<template>
  <div>
    <banner bold-title="Confirmación"
            :title="` de Reserva - ${fair.name}`"
            type="banner--admin"
            description="Completa el siguiente formulario para confirmar tu reserva">
    </banner>
    <v-stepper class="elevation-0" v-model="step" vertical v-show="step!==12 && step!=-1">
      <v-stepper-step :complete="step > 1" step="1" :editable="savedStep>1">
        Información de la solicitud de stands
      </v-stepper-step>

      <v-stepper-content step="1">

        <booking-info
          ref="bookingInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
        ></booking-info>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2" :editable="savedStep>2">
        Otros contactos de interés de la feria
      </v-stepper-step>

      <v-stepper-content step="2">

        <other-interest-contacts
          ref="otherInterestContacts"
          :booking-id="bookingId"
          @nextStep="nextStep"
        ></other-interest-contacts>

      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3" :editable="savedStep>3">
        Información adicional de la empresa
      </v-stepper-step>

      <v-stepper-content step="3">

        <additional-company-info
          ref="additionalCompanyInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4" :editable="savedStep>4">
        Actividad económica
      </v-stepper-step>

      <v-stepper-content step="4">

        <economic-activity
          ref="economicActivity"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 5" step="5" :editable="savedStep>5">
        Reseña de la empresa
      </v-stepper-step>

      <v-stepper-content step="5">

        <company-description
          ref="companyReview"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 6" step="6" :editable="savedStep>6">
        Datos para el envío de la factura
      </v-stepper-step>

      <v-stepper-content step="6">

        <invoice-data
          ref="invoiceData"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 7" step="7" :editable="savedStep>7">
        Resumen de la reserva
      </v-stepper-step>

      <v-stepper-content step="7">

        <booking-summary
          ref="bookingSummary"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 8" step="8" :editable="savedStep>8">
        Información comercial
      </v-stepper-step>

      <v-stepper-content step="8">

        <commercial-info
          ref="commercialInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
          :fair="fair"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 9" step="9" :editable="savedStep>9">
        Empresas que representan en {{ appCountry }} y perfil de su cliente potencial
      </v-stepper-step>

      <v-stepper-content step="9">

        <represented-companies
          ref="commercialInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 10" step="10" :editable="savedStep>10">
        Empresas de interés
      </v-stepper-step>

      <v-stepper-content step="10">

        <target-companies
          ref="interestCompanies"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step :complete="step > 11" step="11" :editable="savedStep>11">
        Participación en ruedas de negocios
      </v-stepper-step>

      <v-stepper-content step="11">

        <participation-business-conference
          ref="ParticipationBusinessConference"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>
    </v-stepper>
    <booking-successful v-if="step==12"></booking-successful>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import BookingInfo from '@/views/private/booking-confirmation/components/BookingInfo'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import OtherInterestContacts from '@/views/private/booking-confirmation/components/OtherInterestContacts'
import AdditionalCompanyInfo from '@/views/private/booking-confirmation/components/AdditionalCompanyInfo'
import CompanyDescription from '@/views/private/booking-confirmation/components/CompanyDescription'
import EconomicActivity from '@/views/private/booking-confirmation/components/EconomicActivity'
import InvoiceData from '@/views/private/booking-confirmation/components/InvoiceData'
import BookingSummary from '@/views/private/booking-confirmation/components/BookingSummary'
import CommercialInfo from '@/views/private/booking-confirmation/components/CommercialInfo'
import TargetCompanies from '@/views/private/booking-confirmation/components/TargetCompanies'
import RepresentedCompanies from '@/views/private/booking-confirmation/components/RepresentedCompanies'
import BookingSuccessful from '@/views/private/booking-confirmation/components/BookingSuccessful'
import ParticipationBusinessConference
from '@/views/private/booking-confirmation/components/ParticipationBusinessConference'
import Banner from '@/components/banner/Banner'
import { BOOKINGS_URL, FAIRS_URL } from '@/constants/ServicesConstants'

export default {
  name: 'BookingConfirmation',
  async created () {
    this.showLoader()
    this.bookingId = this.$route.params.id
    this.fair.id = this.$route.params.fairId
    Promise.all([this.getFair(), this.getCurrentStep()]).then(() => {
      this.hideLoader()
    }).catch(() => {
      this.hideLoader()
      this.showError()
    })
  },
  data () {
    return {
      appCountry: process.env.VUE_APP_COUNTRY,
      step: -1,
      savedStep: -1,
      bookingId: null,
      fair: {
        id: null,
        name: '',
        year: null
      }
    }
  },
  methods: {
    nextStep (step) {
      this.step = step
    },
    async getCurrentStep () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/current-step')
      this.step = response.data
      this.savedStep = response.data
      eventHub.$emit('step' + this.step)
    },

    async getFair () {
      if (this.fair.id) {
        const response = await this.get(`${FAIRS_URL}/${this.fair.id}`)
        this.fair = response.data
      }
    }
  },
  watch: {
    step: (newValue) => {
      eventHub.$emit('step' + newValue)
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin,
    formOperationsMixin
  ],
  components: {
    Banner,
    TargetCompanies,
    CommercialInfo,
    EconomicActivity,
    CompanyDescription,
    AdditionalCompanyInfo,
    OtherInterestContacts,
    BookingInfo,
    InvoiceData,
    BookingSummary,
    RepresentedCompanies,
    ParticipationBusinessConference,
    BookingSuccessful
  }
}
</script>
